import React, { useRef, useEffect, useState } from 'react'
import Button from 'components/Button'
import styles from './home.module.scss'
// import Gallery from '../Gallery/index'
import Invitation from '../Invitation/index'
import WayTo from '../WayTo/index'
import Message from '../Message/index'
import NewGallery from '../Gallery/NewGallery'
import Modal from 'components/Modal'
import { useModal } from 'hooks/useModal'

const Home: React.FC = () => {
  const marginY = '5'
  const wayToRef = useRef<HTMLDivElement>(null)
  const invitationRef = useRef<HTMLDivElement>(null)
  const galleryRef = useRef<HTMLDivElement>(null)
  const messageRef = useRef<HTMLDivElement>(null)

  const [copiedPerson, setCopiedPerson] = useState('')
  const { isModalOpen, openModal, closeModal } = useModal()

  useEffect(() => {
    console.log(isModalOpen)
    return () => setCopiedPerson('')
  }, [isModalOpen])

  useEffect(() => {
    if (!isModalOpen) {
      openModal()
    }
  }, [])

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const offsetTop = ref.current.offsetTop - 100 // 메뉴바 높이만큼 오프셋을 조정합니다.
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className='mb-24'>
      {isModalOpen && (
        <Modal title='주차장 안내' closeModal={closeModal}>
          <p>자차로 오시는 분들은</p>
          <p>주차장 정보를 꼭 확인 부탁드립니다!</p>
          <br />
          <p>예식 시간보다 조금 일찍 오실 경우 식장 바로 앞</p>
          <p>공영주차장 이용가능하나</p>
          <p>시간에 맞춰 오시거나 살짝 늦게 오실 경우</p>
          <p>우림라이온스밸리 주차장으로 안내됩니다.</p>
          <button
            type='button'
            className='text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-lg px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700'
            onClick={() => {
              closeModal()
            }}
          >
            닫기
          </button>
        </Modal>
      )}
      {/* <div className='fixed inset-x-0 flex justify-center mt-10 bottom-8'> */}
      <div className='fixed inset-x-0 z-50 flex justify-center mt-10 bottom-8'>
        <Button
          className={styles.fadeIn}
          style={{ animationDelay: '500ms', marginRight: `${marginY}%` }}
          text='초대글'
          size='large'
          onClick={() => scrollToRef(invitationRef)}
        />
        <Button
          className={styles.fadeIn}
          style={{ animationDelay: '500ms', marginRight: `${marginY}%` }}
          text='오시는길'
          size='large'
          onClick={() => scrollToRef(wayToRef)}
        />
        <Button
          className={styles.fadeIn}
          style={{ animationDelay: '500ms', marginRight: `${marginY}%` }}
          text='사진첩'
          size='large'
          onClick={() => scrollToRef(galleryRef)}
        />
        <Button
          className={styles.fadeIn}
          style={{ animationDelay: '500ms' }}
          text='방명록'
          size='large'
          onClick={() => scrollToRef(messageRef)}
        />
      </div>
      <div ref={invitationRef}>
        <Invitation />
      </div>
      <div ref={wayToRef}>
        <WayTo />
      </div>
      <div ref={galleryRef}>
        {/* <Gallery /> */}
        <NewGallery />
      </div>
      <div ref={messageRef}>
        <Message />
      </div>
    </div>
  )
}

export default Home
