import { FormEvent, useEffect, useState } from 'react'

import { getMessages, postMessage } from 'services/message'
import { MessageDTO } from 'types/supabase'
import { useModal } from 'hooks/useModal'
import MessageModal from './MessageModal'
import Button from 'components/Button'
import { ReactComponent as DeleteIcon } from 'assets/icons/close.svg'
import styles from './message.module.scss'

const initialFormData = {
  name: '',
  password: '',
  description: '',
  created_at: '',
  // created_at: new Date(),
}

const Message = () => {
  const [messages, setMessages] = useState<MessageDTO[]>([])
  const [formData, setFormData] = useState<Omit<MessageDTO, 'id'>>(initialFormData)
  const [selectedMessage, setSelectedMessage] = useState<MessageDTO>()
  const [errorMessage, setErrorMessage] = useState('')
  const { isModalOpen, openModal, closeModal } = useModal()

  useEffect(() => {
    getMessages().then((res) => setMessages(res.messages))
  }, [])

  const handleChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget
    if (name === 'created_at') {
      // value가 비어있지 않다면 ISO 문자열로 변환, 그렇지 않다면 빈 문자열을 유지
      const dateValue = value ? new Date(value).toISOString() : ''
      setFormData((prev) => ({ ...prev, [name]: dateValue }))
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }))
    }
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!formData.name.trim()) {
      setErrorMessage('이름을 입력해주세요.')
      return
    }
    if (!formData.password || formData.password.length < 4) {
      setErrorMessage('비밀번호는 4자리 이상이어야 합니다.')
      return
    }
    if (!formData.description.trim()) {
      setErrorMessage('메시지를 입력해주세요.')
      return
    }
    postMessage(formData).then((res) => {
      setMessages((prev) => [...res.message, ...prev])
    })
    setFormData(initialFormData)
    setErrorMessage('')
  }

  const handleDeleteClick = (message: MessageDTO) => {
    setSelectedMessage(message)
    openModal()
  }

  const formatDateString = (isoString: string) => {
    const date = new Date(isoString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // getMonth()는 0부터 시작하므로 1을 더합니다.
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}_${hour
      .toString()
      .padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
  }

  return (
    <section>
      <h2>방명록</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <input
            type='text'
            name='name'
            placeholder='이름'
            autoComplete='off'
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type='password'
            name='password'
            placeholder='비밀번호'
            autoComplete='off'
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <p className='text-red-600'>비밀번호 4자리 이상 (댓글 삭제 시 필요)</p>
        <textarea
          name='description'
          placeholder='축하 메시지를 남겨주세요.'
          autoComplete='off'
          maxLength={200}
          value={formData.description}
          onChange={handleChange}
        />
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <Button type='submit' text='등록' />
      </form>
      <ul className={styles.messageList}>
        {messages.map((message) => {
          const dateString = formatDateString(message.created_at)
          return (
            <li key={message.id} className={styles.messageItem}>
              <div className={styles.messageHeader}>
                <div>
                  <span>{message.name}</span>
                </div>
                <button type='button' value={message.id} onClick={() => handleDeleteClick(message)}>
                  <DeleteIcon />
                </button>
              </div>
              <p className={styles.description}>{message.description}</p>
              <div className={styles.messageTBox}>
                <span>{dateString}</span>
                {/* <span>{message.created_at.slice(0, 10)}</span> */}
                {/* <span>{message.created_at}</span> */}
                {/* <span>{message.created_at.toISOString()}</span> */}
              </div>
            </li>
          )
        })}
      </ul>
      {isModalOpen && (
        <MessageModal selectedMessage={selectedMessage} setMessages={setMessages} closeModal={closeModal} />
      )}
    </section>
  )
}

export default Message
