import { MouseEvent, useEffect, useState } from 'react'

import { useModal } from 'hooks/useModal'
import Modal from 'components/Modal'
import Button from 'components/Button'
// import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import styles from './bankAccount.module.scss'

const side = {
  bride: '말씀 본문 보기',
  groom: '신랑측',
}

type Side = typeof side[keyof typeof side]

interface Account {
  person: string
  name: string
  bank: string
  account: string
}

const BankAccount2 = () => {
  const [selectedSide, setSelectedSide] = useState<Side>(side.groom)
  const [copiedPerson, setCopiedPerson] = useState('')
  const { isModalOpen, openModal, closeModal } = useModal()

  useEffect(() => {
    return () => setCopiedPerson('')
  }, [isModalOpen])

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setSelectedSide((e.target as HTMLButtonElement).value as Side)
    openModal()
  }

  return (
    <section>
      <div className={styles.buttonWrapper}>
        <Button text={side.bride} value={side.bride} size='fullWidth' buttonStyle='secondary' onClick={handleClick} />
      </div>
      {isModalOpen && (
        <Modal title={selectedSide === side.groom ? side.groom : side.bride} closeModal={closeModal}>
          <p>요한복음 13장 1절</p>
          <p>예수께서 자기가 세상을 떠나</p>
          <p>아버지께로 돌아가실 때가 이른 줄 아시고</p>
          <p>세상에 있는 자기 사람들을 사랑하시되</p>
          <p>끝까지 사랑하시니라</p>
          {/* <p>3. 신랑신부 이두사람 한몸되게 하시고</p>
          <p>5. 온-집안이 하나되고 한뜻되게 하소서</p> */}
          <br />
          {/* <p>2. 세상에서 사는동안 한길가게 하시고</p>
          <p>맘과뜻이 하나되어 주따르게 하소서</p>
          <p>서로믿고 존경하며 서로돕고 사랑해</p>
          <p>고-와 낙을 함께 하며 승리하게 하소서</p>
          <br />
          <p>3. 아버지여 우리들이 기도하고 바람은</p>
          <p>저들부부 세상에서 해로하게 하소서</p>
          <p>이두사람 감화하사 항상 주를 섬기며</p>
          <p>이-세상을 살아갈때 행복하게 하소서 아멘</p> */}
          <br />
          <button
            type='button'
            className='text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-lg px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700'
            onClick={() => {
              closeModal()
            }}
          >
            닫기
          </button>
        </Modal>
      )}
    </section>
  )
}

export default BankAccount2
