import { supabase } from './supabase'
import { MessageDTO } from 'types/supabase'

export const getMessages = async () => {
  const { data } = await supabase.from('comments').select().order('id', { ascending: false })

  return { messages: data || [] }
}

export const postMessage = async (message: Omit<MessageDTO, 'id'>) => {
  const currentDateTime = new Date().toISOString()

  const { data, error } = await supabase
    .from('comments')
    .insert({ ...message, created_at: currentDateTime })
    .select()

  return { message: data || [], error }
}

export const deleteMessage = async (id: number) => {
  const { error } = await supabase.from('comments').delete().eq('id', id)

  return { error }
}
