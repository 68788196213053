import { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Button from 'components/Button'

import gif01 from 'assets/images/GIF_01.jpg'
import gif02 from 'assets/images/GIF_02.jpg'
import image02 from 'assets/images/IMG_0017.jpg'
import image04 from 'assets/images/IMG_0078.jpg'
import image05 from 'assets/images/IMG_0122.jpg'
import image06 from 'assets/images/IMG_0145.jpg'
import image08 from 'assets/images/IMG_0164.jpg'
import image09 from 'assets/images/IMG_0181.jpg'
import image11 from 'assets/images/IMG_0230.jpg'
import image12 from 'assets/images/IMG_0235.jpg'
import image13 from 'assets/images/IMG_0269.jpg'
import image14 from 'assets/images/IMG_0287.jpg'
import image15 from 'assets/images/IMG_0337.jpg'
import image16 from 'assets/images/IMG_0348.jpg'
import image17 from 'assets/images/IMG_0374.jpg'

const imageList = [
  gif01,
  gif02,
  image02,
  image13,
  image12,
  image14,
  image09,
  image08,
  image11,
  image15,
  image16,
  image17,
  image04,
  image05,
  image06,
]

const NewGallery = () => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null)
  const [showAll, setShowAll] = useState(false)

  const displayedImages = showAll ? imageList : imageList.slice(0, 6)

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) {
      setSelectedImage(null)
    }
  }

  return (
    <section className='container p-4 mx-auto'>
      <h1 className='mb-4 text-2xl font-bold'>Photo Gallery</h1>
      <div className='grid grid-cols-3 gap-4'>
        {displayedImages.map((image, index) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt, jsx-a11y/no-noninteractive-element-interactions
          <img
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            src={image}
            alt={`Gallery Image ${index + 1}`}
            className='object-cover w-full h-64 cursor-pointer'
            onClick={() => setSelectedImage(index)}
          />
        ))}
      </div>
      <div className='flex justify-center mt-4'>
        {!showAll ? (
          // eslint-disable-next-line react/button-has-type
          // <button className='px-4 py-2 text-white bg-blue-500 rounded' onClick={() => setShowAll(true)}>
          //   더보기
          // </button>
          <Button text='더보기' size='medium' buttonStyle='secondary' onClick={() => setShowAll(true)} />
        ) : (
          // eslint-disable-next-line react/button-has-type
          // <button className='px-4 py-2 text-white bg-blue-500 rounded' onClick={() => setShowAll(false)}>
          //   접기
          // </button>
          <Button text='접기' size='medium' buttonStyle='secondary' onClick={() => setShowAll(false)} />
        )}
      </div>

      {selectedImage !== null && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75'
          onClick={handleBackgroundClick}
        >
          <div className='relative w-full max-w-3xl'>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className='absolute top-0 right-0 z-50 m-4 text-6xl text-white'
              onClick={() => setSelectedImage(null)}
            >
              &times;
            </button>
            <Carousel
              selectedItem={selectedImage}
              showThumbs={false}
              infiniteLoop
              useKeyboardArrows
              onChange={(index) => setSelectedImage(index)}
            >
              {imageList.map((image, index) => (
                <div key={index} className='flex items-center justify-center h-full'>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  <img src={image} alt={`Carousel Image ${index + 1}`} className='object-contain max-h-screen' />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </section>
  )
}

export default NewGallery
