import styles from './welcome.module.scss'
import main from 'assets/images/main.png'

const Welcome = () => {
  return (
    <section className={styles.welcome}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img src={main} />
      <h2>뜻깊은 자리에 소중한 분들을 초대합니다</h2>
      <p>
        저희 두 사람이 사랑과 믿음으로
        <br />
        한 가정을 이루게 되었습니다.
        <br />
        먼 길 힘드시겠지만,
        <br />
        꼭 오셔서 밝은 미소와 힘찬 박수로 축복해주시면
        <br />
        더없는 기쁨으로 간직하겠습니다.
      </p>
      <div className={styles.host}>
        <div>
          <div>
            <span>신상일 · 이미경</span> 의 아들
          </div>
          <span>우신</span>
        </div>
        <div>
          <div>
            <span>박철진 · 윤진희</span> 의 딸
          </div>
          <span>여울</span>
        </div>
      </div>
    </section>
  )
}

export default Welcome
