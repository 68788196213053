import { Route, Routes } from 'react-router-dom'

import { initializeGTM } from 'hooks/useGTM'
import Layout from 'components/Layout'
import Home from './Home'
import Worship from './Worship/index'
// import Gallery from './Gallery'
// import Invitation from './Invitation'
// import Message from './Message'
// import WayTo from './WayTo'

initializeGTM()

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<Home />} />
        <Route path='/worship' element={<Worship />} />
        {/* <Route path='invitation' element={<Invitation />} />
        <Route path='gallery' element={<Gallery />} />
        <Route path='message' element={<Message />} />
        <Route path='wayto' element={<WayTo />} /> */}
      </Route>
      <Route path='*' element={<div>404</div>} />
    </Routes>
  )
}

export default App
