import { MouseEvent, useState, useEffect } from 'react'
import styles from './location.module.scss'

// import ImageCarousel from 'components/ImageCarousel'
// import image1 from 'assets/images/weddinghall_01.jpg'
// import image2 from 'assets/images/weddinghall_02.jpg'
// import image3 from 'assets/images/weddinghall_03.jpg'
import kakaoIcon from 'assets/icons/kakao_map.png'
import naverIcon from 'assets/icons/naver_map.png'
import googleIcon from 'assets/icons/google_map.png'
import KakaoMap from './KakaoMap'
// import KakaoMapParking from './KakaoMapParking'

import Button from 'components/Button'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'

const addressText = [
  {
    title: '식장 주소',
    description: '인천 부평구 부평대로278번길 16',
  },
  {
    title: '갈산역공영주차장 (2시간무료)',
    description: '인천광역시 부평구 부평대로278번길 8',
  },
  {
    title: '부평우림라이온스밸리 주차장 (3시간무료)',
    description: '인천광역시 부평구 부평대로 283',
  },
  {
    title: '대중교통',
    description: '갈산역 2번 출구 (인천 1호선)',
  },
]

const Location = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  // useEffect(() => {
  //   if (!mapElement.current) return

  //   const location = new naver.maps.LatLng(37.5422, 126.9526)

  //   const mapOptions: naver.maps.MapOptions = {
  //     center: location,
  //     zoom: 16,
  //   }
  //   const map = new naver.maps.Map(mapElement.current, mapOptions)
  //   // eslint-disable-next-line unused-imports/no-unused-vars
  //   const marker = new naver.maps.Marker({
  //     position: location,
  //     map,
  //   })
  // }, [])
  const [copiedPerson, setCopiedPerson] = useState('')
  useEffect(() => {
    return () => setCopiedPerson('')
  }, [])
  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    const { id, value } = e.target as HTMLButtonElement
    navigator.clipboard.writeText(value)
    setCopiedPerson(id)
  }

  return (
    <section>
      <h2>오시는 길</h2>
      <h3 className={styles.location}>식장 주차장이 식장과 도보5분정도 거리가 있습니다.</h3>
      <h3 className={styles.location}>자차로 오시는 분들은 꼭</h3>
      <h3 className={styles.location}>주차 정보 확인 부탁드립니다!</h3>
      <h3 className={styles.location}> </h3>
      <KakaoMap />
      <h3 className={styles.location}>주차장 길찾기 바로가기</h3>
      <div className='mx-10 -10'>
        <div className='flex flex-row justify-center mb-3'>
          <div className={styles.mapIcons}>
            <a
              href='https://m.map.kakao.com/actions/searchView?q=%EB%B6%80%ED%8F%89%EC%9A%B0%EB%A6%BC%EB%9D%BC%EC%9D%B4%EC%98%A8%EC%8A%A4%EB%B0%B8%EB%A6%AC&wxEnc=LLVSSM&wyEnc=QNOPSQS&lvl=4#!/1219716588/map/place'
              target='_blank'
              rel='noreferrer'
            >
              <img src={kakaoIcon} alt='카카오 지도' />
            </a>
          </div>
          <div className={styles.mapIcons}>
            <a href='https://map.naver.com/p/entry/place/19403772?c=15.00,0,0,0,dh' target='_blank' rel='noreferrer'>
              <img src={naverIcon} alt='네이버 지도' />
            </a>
          </div>
          <div className={styles.mapIcons}>
            <a
              href='https://www.google.co.kr/maps/place/%EC%9A%B0%EB%A6%BC+%EB%9D%BC%EC%9D%B4%EC%98%A8%EC%8A%A4%EB%B0%B8%EB%A6%AC+%EA%B0%88%EC%82%B0/data=!4m6!3m5!1s0x357b7dbed9b462b3:0x92c7bb03f732e950!8m2!3d37.5171858!4d126.7201643!16s%2Fg%2F1vfp63vq?hl=ko&entry=ttu'
              target='_blank'
              rel='noreferrer'
            >
              <img src={googleIcon} alt='구글 지도' />
            </a>
          </div>
        </div>
        {/* <KakaoMapParking /> */}
      </div>
      <h3 className={styles.location}>웨스턴팰리스 식장 길찾기 바로가기</h3>
      <div className='mx-10 -10'>
        <div className='flex flex-row justify-center mb-3'>
          <div className={styles.mapIcons}>
            <a
              href='https://m.map.kakao.com/actions/searchView?q=%EC%9B%A8%EC%8A%A4%ED%84%B4%ED%8C%B0%EB%A6%AC%EC%8A%A4%EC%9B%A8%EB%94%A9&wxEnc=LVSOTP&wyEnc=QNLTTMN&lvl=4#!/413941493/map/place'
              target='_blank'
              rel='noreferrer'
            >
              <img src={kakaoIcon} alt='카카오 지도' />
            </a>
          </div>
          <div className={styles.mapIcons}>
            <a href='https://map.naver.com/p/entry/place/1832003609?c=15.00,0,0,0,dh' target='_blank' rel='noreferrer'>
              <img src={naverIcon} alt='네이버 지도' />
            </a>
          </div>
          <div className={styles.mapIcons}>
            <a
              href='https://www.google.co.kr/maps/place/%EC%9B%A8%EC%8A%A4%ED%84%B4%ED%8C%B0%EB%A6%AC%EC%8A%A4%EC%9B%A8%EB%94%A9/data=!3m1!4b1!4m6!3m5!1s0x357b7d001d0e828d:0x8a7d097dbce2a047!8m2!3d37.5158768!4d126.7224966!16s%2Fg%2F11vz9cgjqh?hl=ko&entry=ttu'
              target='_blank'
              rel='noreferrer'
            >
              <img src={googleIcon} alt='구글 지도' />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.address}>
        {addressText.map((text) => (
          <div key={text.title} className={styles.addressItem}>
            {text.description.length !== 0 ? <h4>{text.title}</h4> : <h4 className='text-red-600'>{text.title}</h4>}
            <div className='flex items-center justify-center row-auto'>
              <p className='mr-3'>{text.description}</p>
              {text.description === copiedPerson ? (
                // <div>{text.description.length !== 0 && <CheckIcon />}</div>
                <CheckIcon />
              ) : (
                <Button
                  id={text.description}
                  text='복사'
                  value={text.description}
                  size='small'
                  buttonStyle='ghost'
                  onClick={handleCopy}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {/* <ImageCarousel
        images={[image1, image2, image3]}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        indicatorPosition='outer'
        intervalTime={4000}
        delay={800}
      /> */}
    </section>
  )
}

export default Location
