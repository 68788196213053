// import { useNavigate } from 'react-router-dom'

// import { trackMenuClick } from 'hooks/useGTM'
// import Button from 'components/Button'
import styles from './home.module.scss'
// import BankAccount from './Song1'
import BankAccount2 from './Bible'
import BankAccount3 from './Song2'

const Worship = () => {
  const isShow = true
  return (
    <div className='mt-10'>
      {/* <div className='flex items-center justify-center min-h-screen font-serif bg-amber-100'> */}
      {isShow ? (
        <div className='flex items-center justify-center min-h-screen mb-5 font-serif text-xl bg-amber-100'>
          <div className={styles.fadeIn}>
            {/* <div className='w-full p-5 bg-white rounded-lg shadow-lg'> */}
            {/* <div className='w-[400px] p-5 bg-white rounded-lg shadow-lg'> */}
            <div className='w-screen p-5 bg-white rounded-lg shadow-lg max-w-[600px] min-w-[350px]'>
              <div className='space-y-6'>
                <p className='text-2xl font-black'>결혼예식 순서</p>
                <p className='text-lg font-black'>사회자 : 박광민 전도사</p>
                <div className='flex justify-between'>
                  <span>개 식 사</span>
                  {/* <span className='text-gray-300'>---------------</span> */}
                  <span>사 회 자</span>
                </div>
                <div className='flex justify-between'>
                  <span>화 촉 점 화</span>
                  {/* <span className='text-gray-300'>---------------</span> */}
                  <span>양가 어머니</span>
                </div>
                <div className='flex justify-between'>
                  <span>신랑 · 신부 입장</span>
                  {/* <span className='text-gray-300'>---------------</span> */}
                  <span>신랑 · 신부</span>
                </div>
                <div className='flex justify-between'>
                  <span>찬 송</span>
                  <span>오늘 모여 찬송함은 605장</span>
                  <span>다 같 이</span>
                </div>
                <BankAccount3 />
                <div className='flex justify-between'>
                  <span>축 복 기 도</span>
                  {/* <span className='text-gray-300'>---------------</span> */}
                  <span>황선돈 목사님</span>
                </div>
                {/* <div className='flex justify-between'>
                  <span>찬송</span>
                  <span>오늘 모여 찬송함은</span>
                  <span>605장</span>
                </div> */}
                {/* <BankAccount /> */}
                <div className='flex justify-between'>
                  <span>성 경 봉 독</span>
                  <span>요한복음 13장 1절</span>
                  <span>사 회 자</span>
                </div>
                {/* <div className='flex justify-center'>
                  <span>제목 : 말씀제목입니다.</span>
                </div> */}
                <BankAccount2 />
                <div className='flex justify-between'>
                  <span>말 씀</span>
                  {/* <span className='text-gray-300'>----------</span> */}
                  <span>이승원 목사님</span>
                </div>
                <div className='flex justify-between'>
                  <span>성 혼 서 약</span>
                  {/* <span className='text-gray-300'>----------</span> */}
                  <span>이승원 목사님</span>
                </div>
                <div className='flex justify-between'>
                  <span>성 혼 기 도</span>
                  {/* <span className='text-gray-300'>----------</span> */}
                  <span>이승원 목사님</span>
                </div>
                <div className='flex justify-between'>
                  <span>성 혼 선 포</span>
                  {/* <span className='text-gray-300'>----------</span> */}
                  <span>이승원 목사님</span>
                </div>
                <div className='flex justify-between'>
                  <span>축가</span>
                  {/* <span className='text-gray-300'>----------</span> */}
                  <span>박귀옥 · 정미희</span>
                </div>
                <div className='flex justify-between'>
                  <span>축가</span>
                  {/* <span className='text-gray-300'>----------</span> */}
                  <span>홍진주 · 임태은</span>
                </div>

                <div className='flex justify-between'>
                  <span>인 사</span>
                  {/* <span className='text-gray-300'>---------------</span> */}
                  <span>신랑 · 신부</span>
                </div>
                <div className='flex justify-between'>
                  <span>행 진</span>
                  {/* <span className='text-gray-300'>---------------</span> */}
                  <span>신랑 · 신부</span>
                </div>
                <div className='mt-6 text-center'>
                  <p>긴 예식순서동안</p>
                  <p>자리 지켜주셔서 감사드립니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex items-center justify-center h-16 min-h-screen mb-5 font-serif text-xl bg-amber-100'>
          <div className={styles.fadeIn}>
            <div className='w-[350px] p-5 bg-white rounded-lg shadow-lg'>
              <div className='space-y-6'>
                <p className='text-2xl font-black'>결혼예식 순서</p>
                <div className='mt-6 text-center'>
                  <p>본 예식은 결혼예배로 진행됩니다.</p>
                  <p>예식순서 준비 중 입니다.</p>
                  <p>9월에 확인해주세요 :)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Worship
