import { Link } from 'react-router-dom'

import styles from './gnb.module.scss'
import NavBar from './NavBar'

const GNB = () => {
  return (
    <header className={styles.gnb}>
      <div className={styles.headerWrapper}>
        <div className={styles.logoWrapper}>
          {/* <Link to='/'>여울 & 우신</Link> */}
          <Link to='/'>24.10.05 토 12:10 웨스턴팰리스 갈산</Link>
        </div>
        {/* <div className="p-4 text-red-500">
          <Link to='/'>여울 & 우신</Link>
        </div> */}
        <NavBar />
      </div>
    </header>
  )
}

export default GNB
