import React, { useEffect, useRef } from 'react'

const KakaoMap: React.FC = () => {
  const location1 = { lat: 37.5171858, lng: 126.7201643, title: '부평우림라이온스밸리 ', displayTitle: '주차장' }
  const location2 = { lat: 37.5158628, lng: 126.7225115, title: '웨스턴팰리스', displayTitle: '예식장' }
  const mapContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mapContainer.current && window.kakao && window.kakao.maps) {
      const mapOption = {
        center: new window.kakao.maps.LatLng((location1.lat + location2.lat) / 2, (location1.lng + location2.lng) / 2),
        level: 3,
      }

      const map = new window.kakao.maps.Map(mapContainer.current, mapOption)

      const locations = [location1, location2]

      locations.forEach((location) => {
        const markerPosition = new window.kakao.maps.LatLng(location.lat, location.lng)

        const imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png'
        const imageSize = new window.kakao.maps.Size(64, 69)
        const imageOption = { offset: new window.kakao.maps.Point(27, 69) }

        const markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption)

        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
          image: markerImage,
        })

        marker.setMap(map)

        const iwContent = `
          <div style="padding:5px;width: 170px;text-align: center;">
            ${location.displayTitle} <br>
            <a href="https://map.kakao.com/link/map/${location.title},${location.lat},${location.lng}" style="color:blue" target="_blank">${location.title}</a> 
            <a href="https://map.kakao.com/link/to/${location.title},${location.lat},${location.lng}" style="color:blue" target="_blank"></a>
          </div>`
        const iwPosition = new window.kakao.maps.LatLng(location.lat, location.lng)

        const infowindow = new window.kakao.maps.InfoWindow({
          position: iwPosition,
          content: iwContent,
        })

        infowindow.open(map, marker)
      })
    }
  }, [])

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px' }}>
      <div ref={mapContainer} className='z--20' style={{ width: '100%', height: '100%' }} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'transparent',
          zIndex: 1,
          pointerEvents: 'none', // Ensure the overlay does not block pointer events
        }}
      />
    </div>
  )
}

export default KakaoMap
